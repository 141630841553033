






























// library
import Vue, { VueConstructor } from 'vue';
import formSubmit from '@/mixins/formSubmit';
// components
import { OrgModel } from '@/types/org';
import { UserDetails } from '@/types/profile';
import Btn from '@/components/buttons/Btn.vue';
import ApiService from '@/services/api.service';
import notify from '@/util/notify';
import AppModal from '@/components/AppModal.vue';

export default (Vue as VueConstructor<Vue & InstanceType<typeof formSubmit>>).extend({
  name: 'RemoveUserFromProject',
  components: {
    Btn,
    AppModal,
  },
  props: {
    currentUser: String,
  },
  computed: {
    org(): OrgModel {
      return this.$store.getters['orgModule/details'];
    },
    user(): Partial<UserDetails> {
      return this.$store.getters['profileModule/user'];
    },
    projectId(): string {
      return this.$route.params.projectId;
    },
  },
  methods: {
    isCurrentUser(userId: string): boolean {
      return this.user.id === userId;
    },
    async open() {
      await (this.$refs.modal as any).open();
    },
    async removeUserFromProject() {
      try {
        await ApiService.put(`/project/${this.projectId}/remove`, { ids: [this.currentUser] });
        if (this.isCurrentUser(this.currentUser)) {
          notify.success('You have successfully left the project');
          this.$emit('submit');
          await this.$router.push(`/org/${this.org.id}`);
        } else {
          notify.success('User successfully removed from the project');
          this.$emit('submit');
          (this.$refs.modal as any).close();
        }
      } catch {
        notify.danger('User could not be removed from the project');
        (this.$refs.modal as any).close();
      }
    },
  },
});
