


















































// library
import Vue, { VueConstructor } from 'vue';
import formSubmit from '@/mixins/formSubmit';
// components
import { UserDetails } from '@/types/profile';
import ApiService from '@/services/api.service';
import notify from '@/util/notify';
import RemoveUserFromProject from '@/views/projects/users/RemoveUserFromProject.vue';
import { availableRoles, RoleInformation } from '@/components/admin/org/roleInformation';
import { Roles } from '@/types';
import ListWithFilter from '@/components/list/ListWithFilter.vue';

export default (Vue as VueConstructor<Vue & InstanceType<typeof formSubmit>>).extend({
  name: 'ManageProjectUsers',
  components: {
    ListWithFilter,
    RemoveUserFromProject,
  },
  computed: {
    searchUrl(): string {
      return `/org/${this.orgId}/user/info?role=${Roles.PROJECT_MANAGER}&inProject=${this.projectId}`;
    },
    orgId(): string {
      return this.$route.params.orgId;
    },
    projectId(): string {
      return this.$route.params.projectId;
    },
    user(): Partial<UserDetails> {
      return this.$store.getters['profileModule/user'];
    },
  },
  data() {
    return {
      roleOptions: [] as any,
      roleMappings: {} as any,
      extraColumns: [
        {
          name: 'title',
          label: 'Title',
          field: 'title',
          align: 'center',
          sortable: true,
        },
        {
          name: 'roleWithinOrg',
          label: 'Organisational Role',
          field: 'roleWithinOrg',
          align: 'center',
          sortable: false,
        },
        {
          name: 'roleWithinProject',
          label: 'Current Project Role',
          field: 'roleWithinProject',
          align: 'center',
          sortable: false,
        },
      ],
    };
  },
  methods: {
    async open() {
      await (this.$refs.modal as any).open();
    },
    async addUserToProject(role: Roles, userId: string, loadRows: () => void) {
      try {
        await ApiService.put(`/project/${this.projectId}/assign`, { users: [{ id: userId, role }] });
        await loadRows();
        notify.success('User successfully added to the project');
      } catch {
        notify.danger('User could not be added to the project');
      }
    },
  },
  async created() {
    this.roleOptions = availableRoles.slice(4);
    availableRoles.map((r: RoleInformation) => this.roleMappings[r.type] = r.name);
  },
});
